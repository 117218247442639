import { IconButton } from '@chakra-ui/button';

import logo from '../../images/br.png';
import {
    Stack,
    Flex,
    Heading,
    Spacer,
    HStack,
    Text,
    Button,
    Image,
} from "@chakra-ui/react";
import { FaInstagram, FaLinkedin, FaGithub } from 'react-icons/fa';

const Navbar = () => {
    return (
        <Stack
            p={5}
            bg={'gray.50'}
            as='header'
        >
            <Flex
                w='full'
                alignItems={'center'}
            >
               
                <Image
                                alt={'Hero Image'}
                                fit={'contain'}
                                align={'center'}
                                w={'14%'}
                                h={'100%'}
                                src={logo}
                                draggable='false'
                            />
                <Spacer></Spacer>
                
            </Flex>

        </Stack>
    )
}

export default Navbar
